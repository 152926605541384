<template>
  <div class="frameContainer">
    <iframe
      v-if="enabled"
      class="frame"
      width="100%"
      height="100%"
      :srcDoc="srcDoc"
    ></iframe>
    <div v-else>
      <div class="text-h4 pt-16 text-center">
        Sanal Tur alanı canlı yayın sırasında açılacaktır.
      </div>
    </div>
  </div>
</template>

<route>
{
  "auth": false
}
</route>

<script>
import srcDoc from '!!raw-loader!../assets/360.html';

export default {
  name: 'Expo',
  components: {},
  head() {
    return {
      title: this.$t('exhibition.title')
    };
  },
  data() {
    return {
      srcDoc,
      enabled: true
    };
  },
  /* sockets: {
    expo_update(data) {
      this.enabled = data;
    }
  },
  created() {
    this.$socket.emit('call', 'booth.getExpo', (err, data) => {
      this.enabled = data;
    });
  }, */
  computed: {}
};
</script>

<style>
.frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
}

.frameContainer {
  width: 100vw;
  height: calc(100vh - 65px);
}
</style>
